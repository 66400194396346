import { HttpHeaders } from '@angular/common/http';
export const Constants = {

    theme_name: 'WiSolar Portal',
    current_year: new Date().getFullYear(),
    home_link: '/index',

    VENDOR: 'WiSolar',
    VERSION: '1.0',
    YEAR: new Date().getFullYear(),
    PORTAL_URL: 'portal',

    serviceUrl: 'https://testapp.wisolar.co/solar/api/',

    NoTokenHttpHeaders: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/collection+json',
            'Access-Control-Allow-Origin': '*',
            'No-Auth': 'True'
        })
    },

    BlobHttpHeaders: {
        headers: new HttpHeaders({
            'No-Auth': 'False',
        })
    },

    TokenHttpHeaders: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/collection+json',
            'Access-Control-Allow-Origin': '*',
            'No-Auth': 'False'
        })
    },

    AccessToken: 'omnibranches-access-token',
    RefreshToken: 'omnibranches-refresh-token',
    userProfileStore: 'omnibranches_user_data',
    ApiKey: 'pk_test_561ba5e8633e75ec2653daa8302050fb78f1f22f',

    layout: {
        sidebar: {
            always_drawer_mode: false,
        },
    },

    colors: {
        primary: '#2949EF',
        secondary: '#6c757d',
        success: '#06b5b6',
        info: '#00bcd4',
        warning: '#fd7e14',
        danger: '#FE4D2E',
        light: '#dee2e6',
        purple: '#6f42c1',
        indigo: '#6610f2',
        pink: '#e83e8c',
        yellow: '#FDA424',
        teal: '#20c997',
    },

};

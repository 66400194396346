import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, retry, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs/index';
import {User} from '../models/user/user.model';

import {PagedDataModel} from '../models/paged-data.model';
import {Constants} from '../../config/index';
import {Router} from "@angular/router";
import {environment} from '../../../environments/environment';
import { IContactInformation } from '../models/contact-information.model';
import { ICountry, ICurrency } from '../models/currency/currency.model';

// export interface PagedTransactionData extends PagedDataModel {
//   data: Array<Transaction>;
// }

export interface PagedCurrencyData extends PagedDataModel {
  data: Array<ICurrency>;
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public static isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public static userData: BehaviorSubject<User> = new BehaviorSubject(new User(''));
  private serviceUrl = environment.apiUrl;

  public static get UserProfile() {
    if (localStorage.getItem(Constants.userProfileStore) !== null) {
      return new User(JSON.parse(localStorage.getItem(Constants.userProfileStore)));
    }
    return new User('');
  }

  public static checkIfLoggedIn() {
    if (localStorage.getItem(Constants.AccessToken) !== '' && localStorage.getItem(Constants.AccessToken) &&
      localStorage.getItem(Constants.AccessToken) !==  undefined ) {
      return this.isLoggedIn.next(true);
    } else {
      return this.isLoggedIn.next(false);
    }
  }

  public static getUserData() {
    if (localStorage.getItem(Constants.userProfileStore) !== '' && localStorage.getItem(Constants.userProfileStore) &&
      Constants.userProfileStore !== undefined) {
      return this.userData.next(new User(JSON.parse(localStorage.getItem(Constants.userProfileStore))));
    } else {
      return new User('');
    }
  }

  public static getLoggedInStatus() {
    return localStorage.getItem(Constants.userProfileStore) != null;
  }

  public static saveUserData(user: User) {
    localStorage.setItem(Constants.userProfileStore, JSON.stringify(user));
    UtilityService.getUserData();
  }

  public static setLoggedInStatus(value: boolean) {
    UtilityService.isLoggedIn.next(value);
    UtilityService.getUserData();
  }
  
  constructor(private http: HttpClient, private router: Router) {}

  getTransactionCount() {
    return this.http.get(this.serviceUrl + 'report/transaction-count', Constants.TokenHttpHeaders).pipe(
      retry(0),
      map((res: any) => res.data)
    );
  }

   logOutUser() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getPermissions() {
    return this.http.get(this.serviceUrl + 'role/permissions?unPaged=true', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getPaymentTypes() {
    return this.http.get(this.serviceUrl + 'utilities/payment-types', Constants.TokenHttpHeaders).pipe(
        retry(0)
    );
  }

  getStates() {
    return this.http.get(this.serviceUrl + 'utilities/states', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getContactInformation() : Observable<IContactInformation> {
    return this.http.get<IContactInformation>(this.serviceUrl + 'system/contact-details', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getCurrencyConversion(page = 1, pageSize = 20, search?: string, unPaged = false): Observable<PagedCurrencyData> {
    const args = `?page=${page}&pageSize=${pageSize}${search ? '&search=' +search: ''}${unPaged ? '&unPaged=' +unPaged: ''}`;
    return this.http.get(this.serviceUrl+ 'currency-conversion/' + args, Constants.TokenHttpHeaders).pipe(
        map((res: any) => res )
    );
  }

  deleteCurrency(currencyId: number) {
    return this.http.delete(this.serviceUrl + 'currency-conversion/' + currencyId, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  addCurrencyExchangeRate(payload) {
    return this.http.post(this.serviceUrl + 'currency-conversion/add-conversion', payload, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  updateCurrencyExchangeRate(id: number, payload: {exchangeRateAmount: number}) {
    return this.http.put(this.serviceUrl + 'currency-conversion/'+ id +'/update-conversion', payload, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  updateContactInformation(payload, url) {
    return this.http.post(this.serviceUrl + 'system/'+ url, payload, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getSystemProductCommission() {
    return this.http.get(this.serviceUrl + 'commission/system-product-commission', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  updateSystemProductCommission(percentage: number) {
    return this.http.post(this.serviceUrl + 'commission/system-product-commission', 
    { 'percentage': percentage }, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getSystemRechargeCommission() {
    return this.http.get(this.serviceUrl + 'commission/system-recharge-commission', Constants.TokenHttpHeaders).pipe(
      retry(0),
      map(data => data)
    );
  }

  getSystemPricePerKwh() {
    return this.http.get(this.serviceUrl + 'system/get-price-per-kwh', Constants.TokenHttpHeaders).pipe(
      retry(0),
      map(data => data)
    );
  }

  updateSystemPricePerKwh(payload, id) {
    return this.http.put(this.serviceUrl + 'system/update-price-per-kwh/'+ id,
    payload, Constants.TokenHttpHeaders).pipe(retry(0));
  }

  updateSystemRechargeCommission(percentage: number) {
    return this.http.post(this.serviceUrl + 'commission/system-recharge-commission', 
    { 'percentage': percentage }, Constants.TokenHttpHeaders).pipe(
      retry(0),
      map(data => data)
    );
  }

  getLGASbyStateId(stateId: number) {
    // GET /utilities/lga/{stateId}
    return this.http.get(this.serviceUrl + 'utilities/lga/'+ stateId, Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getLGA() {
    return this.http.get(this.serviceUrl + 'utilities/lga', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(this.serviceUrl + 'lookup/countries', Constants.TokenHttpHeaders).pipe(
      retry(0)
    );
  }

  uploadImageFile(imageFile: FormData): any {
    return this.http.post(this.serviceUrl + 'utility/upload', imageFile, {
      reportProgress: true, observe: 'events'
    }).pipe(
      retry(2)
    );
  }
}

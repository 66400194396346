
<ul class="sidebar-menu metismenu" id="sidenav">
	<ng-container *ngFor="let item of navigation">
		<ng-container *ngIf="item.heading; else unset">
			<li class="heading"><span>{{item.title}}</span></li>
		</ng-container>

		<ng-template #unset>

			<ng-container *ngIf="item.children.length == 0; else hasChildBlock">
				<li *ngIf="!item.exact">
					<a routerLink="{{item.url}}" routerLinkActive="active">
						<i class="sidebar-item-icon" [ngClass]="item.icon"></i>
						<span class="nav-label">{{(item.translate | translate) || item.title}}</span>
					</a>
				</li>
				<li *ngIf="item.exact">
					<a routerLink="{{item.url}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
						<i class="sidebar-item-icon" [ngClass]="item.icon"></i>
						<span class="nav-label">{{item.title}}</span>
					</a>
				</li>
			</ng-container>

			<ng-template #hasChildBlock>
				<li routerLinkActive="active">
					<a href='javascript:;'>
						<i class="sidebar-item-icon" [ngClass]="item.icon"></i>
						<span class='nav-label'>{{(item.translate | translate) || item.title}}</span>
						<i class="arrow la la-angle-right"></i>
					</a>
					<ul class="nav-2-level collapse" routerLinkActive="in">
						<ng-container *ngFor="let child of item.children">
							<ng-container *ngIf="!child.children">
								<li *ngIf="child.exact"><a routerLink="{{item.url + child.url}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{child.title}}</a></li>
								<li *ngIf="!child.exact"><a routerLink="{{item.url + child.url}}" routerLinkActive="active">{{child.title}}</a></li>
							</ng-container>
							<ng-container *ngIf="child.children">
								<li routerLinkActive="active">
									<a href="javascript:;">
										<span class="nav-label">{{child.title}}</span>
										<i class="arrow la la-angle-right"></i>
									</a>
									<ul class="nav-3-level collapse" routerLinkActive="in">
										<li *ngFor="let child2 of child.children">
											<a routerLink="{{item.url + child.url + child2.url}}" routerLinkActive="active">{{child2.title}}</a>
										</li>
									</ul>
								</li>
							</ng-container>
						</ng-container>
					</ul>
				</li>
			</ng-template>
		</ng-template>
	</ng-container>
</ul>

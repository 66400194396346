
<ngb-tabset class='col p-0 h-100 d-flex flex-column nav-pills-solid' type="pills">
	<ngb-tab title="Messages">
		<ng-template ngbTabContent>
	        <perfect-scrollbar>
	            <div class="flexbox px-4 mb-4 mt-3">
	                <span class="text-muted">23 Messages</span>
	                <a routerLink='/apps/mail'>View All</a>
	            </div>
	            <div class="list-group list-group-flush">
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u1.jpg" alt="image" width="40">
	                            <span class="badge-point badge-warning avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u2.jpg" alt="image" width="40">
	                            <span class="badge-point badge-warning avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u3.jpg" alt="image" width="40">
	                            <span class="badge-point badge-success avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u5.jpg" alt="image" width="40">
	                            <span class="badge-point badge-warning avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u8.jpg" alt="image" width="40">
	                            <span class="badge-point badge-success avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u5.jpg" alt="image" width="40">
	                            <span class="badge-point badge-warning avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u9.jpg" alt="image" width="40">
	                            <span class="badge-point badge-success avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	                <a class="list-group-item list-group-item-action px-4 py-3" href="#">
	                    <div class="media">
	                        <div class="mr-3 position-relative">
	                            <img class="rounded-circle align-self-center" src="./assets/img/users/u6.jpg" alt="image" width="40">
	                            <span class="badge-point badge-warning avatar-badge"></span>
	                        </div>
	                        <div class="media-body">
	                            <div class="flexbox mb-2">
	                                <h6 class="mb-0">John Due</h6>
	                                <div class="text-muted font-13">2 days ago</div>
	                            </div>
	                            <div class="font-13">Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor sed.</div>
	                        </div>
	                    </div>
	                </a>
	            </div>
	        </perfect-scrollbar>			
		</ng-template>
	</ngb-tab>
	<ngb-tab title="Settings">
		<ng-template ngbTabContent>
			<div class="p-4 pb-3 h-100">
		        <perfect-scrollbar>
		            <h5 class="mb-4">App Settings</h5>
		            <div class="flexbox py-3">Enable notifications:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox" checked="">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">SMS notifications:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Chat history:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Show recent activity:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox" checked="">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Users log:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Site Tracking:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox" checked="">
		                    <span></span>
		                </label>
		            </div>
		            <h5 class="mb-4 mt-5">Orders Settings</h5>
		            <div class="flexbox py-3">Orders history:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox" checked="">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Notify on new orders:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Sales reports:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Orders reports:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox" checked="">
		                    <span></span>
		                </label>
		            </div>
		            <div class="flexbox py-3">Order auto status:
		                <label class="ui-switch switch-solid">
		                    <input type="checkbox">
		                    <span></span>
		                </label>
		            </div>
		        </perfect-scrollbar>
		    </div>
		</ng-template>
	</ngb-tab>
	<ngb-tab title="Logs">
		<ng-template ngbTabContent>
			<div class="p-4 pb-3 h-100">
		        <perfect-scrollbar>
		            <ul class="timeline timeline-default outline-points">
		                <li class="timeline-item">2 Issue fixed
		                    <span class="font-13 text-muted ml-2">Just now</span>
		                </li>
		                <li class="timeline-item">
		                    <span>15 New orders
		                        <span class="badge badge-primary badge-pill ml-2">important</span>
		                    </span>
		                    <span class="font-13 text-muted ml-2">5 mins</span>
		                </li>
		                <li class="timeline-item">18 new orders sent
		                    <span class="font-13 text-muted ml-2">24 mins</span>
		                </li>
		                <li class="timeline-item">15 New messages
		                    <span class="font-13 text-muted ml-2">45 mins</span>
		                </li>
		                <li class="timeline-item">The invoice is ready
		                    <span class="font-13 text-muted ml-2">1 hrs</span>
		                </li>
		                <li class="timeline-item">
		                    <span>Server Error
		                        <span class="badge badge-success badge-pill ml-2">resolved</span>
		                    </span>
		                    <span class="font-13 text-muted ml-2">2 hrs</span>
		                </li>
		                <li class="timeline-item">
		                    <span>System Warning
		                        <a class="text-purple ml-2">Check</a>
		                    </span>
		                    <span class="font-13 text-muted ml-2">12:07</span>
		                </li>
		                <li class="timeline-item">24 new users registered
		                    <span class="font-13 text-muted ml-2">12:30</span>
		                </li>
		                <li class="timeline-item">
		                    <span>5 New Orders
		                        <span class="badge badge-warning badge-pill ml-2">important</span>
		                    </span>
		                    <span class="font-13 text-muted ml-2">13:45</span>
		                </li>
		                <li class="timeline-item">
		                    <span class="flexbox">Production server up<i class="la la-arrow-circle-up font-18 ml-2 text-success"></i></span>
		                    <span class="font-13 text-muted ml-2">1 days ago</span>
		                </li>
		                <li class="timeline-item">Server overloaded 91%
		                    <span class="font-13 text-muted ml-2">2 days ago</span>
		                </li>
		                <li class="timeline-item">Server error
		                    <span class="font-13 text-muted ml-2">2 days ago</span>
		                </li>
		                <li class="timeline-item">2 Issue fixed
		                    <span class="font-13 text-muted ml-2">Just now</span>
		                </li>
		                <li class="timeline-item">
		                    <span>15 New orders
		                        <span class="badge badge-primary badge-pill ml-2">important</span>
		                    </span>
		                    <span class="font-13 text-muted ml-2">5 mins</span>
		                </li>
		                <li class="timeline-item">18 new orders sent
		                    <span class="font-13 text-muted ml-2">24 mins</span>
		                </li>
		            </ul>
		        </perfect-scrollbar>
		    </div>
		</ng-template>
	</ngb-tab>
</ngb-tabset>	

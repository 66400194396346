
<!-- BEGIN: Sidebar-->
<app-sidebar class="page-sidebar pb-0" id="sidebar" #sidebar></app-sidebar>
<!-- END: Sidebar-->

<!-- BEGIN: Content-->
<div class="content-area">
	<!-- BEGIN: Header-->
	<app-header class="navbar navbar-expand navbar-light fixed-top header"></app-header>
	<!-- END: Header-->

	<!-- BEGIN: content-->
	<div class="page-content fade-in-up">
		<router-outlet></router-outlet>
	</div>
	<!-- END: content-->

	<!-- BEGIN: Footer-->
	<app-footer class='page-footer'></app-footer>
	<!-- END: Footer-->
</div>

<!-- BEGIN: Quick Sidebar-->
<app-quick-sidebar id='quick-sidebar' class='quick-sidebar' #quickSidebar></app-quick-sidebar>
<!-- END: Quick Sidebar-->

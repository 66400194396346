<ul class="navbar-nav mr-auto">
  <li class="nav-item">
    <a class="nav-link navbar-icon sidebar-toggler" id="sidebar-toggler" href="javascript:;" (click)="toggleSidebar($event)">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </a>
  </li>
</ul>
<ul class="navbar-nav">
  <li class="nav-divider"></li>
  <li class="nav-item dropdown" ngbDropdown placement='bottom-right'>
    <a class="nav-link dropdown-toggle no-arrow d-inline-flex align-items-center" ngbDropdownToggle href="javascript:;">
      <span class="d-none d-sm-inline-block mr-2">{{(loggedInUser?.firstName ? loggedInUser?.firstName : '')}}</span>
      <img class="rounded-circle" src="assets/img/users/admin-image.png" alt="image" width="36" />
    </a>
    <div class="dropdown-menu pt-0 pb-4" ngbDropdownMenu style="min-width: 280px;">
      <div class="p-4 mb-4 media align-items-center text-white" style="background-color: #2c2f48;">
        <img class="rounded-circle mr-3" src="./assets/img/users/admin-image.png" alt="image" width="55" />
        <div class="media-body">
          <h5 class="mb-1">{{loggedInUser?.firstName + ' ' + loggedInUser?.lastName}}</h5>
          <div class="font-13">{{ loggedInUser?.roleName}}</div>
        </div>
      </div>
      <a class="dropdown-item d-flex align-items-center" routerLink="/account"><i class="ft-user mr-3 font-18 text-muted"></i>My Account</a>
      <!--<a class="dropdown-item d-flex align-items-center" href="javascript:;"><i class="ft-settings mr-3 font-18 text-muted"></i>Settings</a>-->
      <div class="dropdown-divider my-3"></div>
      <div class="mx-4">
        <a class="btn btn-link p-0" (click)="logOut()"><span class="btn-icon"><i class="ft-power mr-2 font-18"></i>Logout</span></a>
      </div>
    </div>
  </li>
</ul>

<ng-template #searchModal let-modal>
  <form class="search-top-bar" action="javascript:;">
    <input class="form-control search-input" type="text" placeholder="Search...">
    <button class="reset input-search-icon" type="submit"><i class="ft-search"></i></button>
    <button class="reset input-search-close" type="button" data-dismiss="modal" (click)="modal.dismiss()"><i class="ft-x"></i></button>
  </form>
</ng-template>

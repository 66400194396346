export const environment = {
  production: false,
  url: 'https://testapp.wisolar.com',
  apiUrl: 'https://testapp.wisolar.co/solar/api/',
  defaultauth: 'fackbackend',
  version: 'v2.0.1-20241211',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};
